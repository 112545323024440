<template>
   <div class="base-line"></div>
</template>

<script>

export default {
  name: 'BaseLine'
};
</script>


<style scoped>
  .base-line {
    width: 100%;
    height: 1px;
    border-top: 1px solid #d3cdc2;
    margin: 30px;
  }

</style>